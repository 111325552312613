import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout, Testimonials } from '../../components';
import styles from './styles/index.module.scss';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location: GatsbyLocation;
  data: any;
  pageContext: any;
}

const IndexPage: React.FunctionComponent<PageProps> = (props) => {
  const { markdownRemark } = props.data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout locale={props.pageContext.locale} location={props.location}>
      <main>
        <section className={styles.splash}>
          <div
            className={[
              styles.textImageComponent,
              styles.textImageComponentBottomLeft,
            ].join(' ')}
          >
            <div
              className={styles.textImageComponentImage}
              style={{
                backgroundImage: "url('" + frontmatter.main_image + "')",
              }}
            ></div>
            <article
              className={styles.textImageComponentText}
              dangerouslySetInnerHTML={{ __html: html }}
            ></article>
          </div>
        </section>
        <Testimonials testimonials={frontmatter.testimonials} />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query($filepath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $filepath }) {
      html
      frontmatter {
        nav_menu
        testimonials {
          author
          quote
        }
        main_image
        title
        short_description
        path
      }
    }
  }
`;

export default IndexPage;
